<template>
  <v-layout style=" box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);">
    <v-app-bar
        prominent
        :class="$vuetify.display.smAndDown? 'small_footer_radius' : 'big_footer_radius'"
    >
      <v-app-bar-title v-on:click="goToHome">

      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main
        class="align-self-start"
        :class="($vuetify.display.smAndDown) ? 'small_footer_radius_main' : 'big_footer_radius_main'"
    >
      <div>
        <v-parallax style="background: linear-gradient(135deg, rgb(159, 46, 244), rgb(249, 125, 132), rgb(255, 205, 66));"
                    :with="$vuetify.display.smAndDown ? 690 : 1200"
                    :height="$vuetify.display.smAndDown ? 1080 : 1920"
        >
          <div class="d-flex flex-column fill-height justify-center align-center">

            <h1 class="subheading text-lg-h2 text-xs-h7 text-sm-h4 text-md-h3  font-weight-black ma-6 " style="color: #ffffff" align="center">
              «Мы проводим исследование об изменении трудовой функции учителя в современных условиях»
            </h1>
            <h4 class="subheading text-lg-h5 text-xs-h8 text-md-h7 font-weight-black ma-8" style="color:#ffffff;">
              Предлагаем Вам принять участие в исследованиях. В приведенных ниже вопросах отметьте тот вариант (или те варианты) ответа, которые вы считаете наиболее подходящими для Вас.
              Проведение опроса является анонимным и не предполагает сбора персональных данных и идентификации респондентов. Ваше мнение нам очень важно и будет учтено в выводах исследования!
            </h4>
            <v-btn
                rounded="pill"
                size="x-large"
                variant="outlined"
                class="ma-8"
                color="#ffffff"
                v-scroll-to="'#action_place'"
            >
              Пройти анкетирование
            </v-btn>
          </div>
        </v-parallax>
        <v-dialog
            v-model="showLoader"
            persistent
        ><v-alert><span class="text-h5 text-primary">Загрузка данных <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular></span></v-alert>
        </v-dialog>

        <section id="action_place" >

          <div >
            <div class="py-12"></div>
            <v-container class="text-center" >
              <h2 class="display-2 font-weight-bold mb-3">Анкета участника исследования</h2>

              <div>
                <v-responsive
                    class="mx-auto mb-8"
                    width="56"
                >
                  <v-divider class="mb-1"></v-divider>
                </v-responsive>
                <v-responsive
                    class="mx-auto "
                    max-width="1080"

                ><v-container  v-if="show===false" >
                  <v-row>
                    <v-col>
                      <v-btn
                          rounded="pill"
                          size="x-large"
                          variant="outlined"
                          class="ma-8"
                          color="#579AEA"
                          @click="beginQuestionnaire"
                      >
                        Сгенерировать анкету
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                  <v-container v-if="show===true" >
                    <v-row>
                      <v-col>
                        <v-card v-for="(item, index) in sortedCretarias" :key="index" class="ma-6" min-width="600px;">
                          <v-card-title class="card_ligth_header">
                            <span class="text-h6 left">{{item.criteria.criteriaBody}}</span>
                          </v-card-title>
                          <v-divider></v-divider>

                          <v-card-text >
                            <v-row  v-for="(itemL, findex) in sortedOptions(item)" :key="findex" class="text-left" justify="end" >
                              <v-col cols="2"  md="2"  sm="2" xl="2" lg="2" style="display: flex; align-items: center;">
                                <v-switch style="display: flex; align-items: center;"
                                          color="primary"
                                          v-model="itemL.checked"
                                          v-on:change="selectItem(itemL.checked, itemL.criteriaId, itemL.id, this.uuid)">

                                </v-switch>
                              </v-col>
                              <v-col cols="10"  md="10"  sm="10" xl="10" lg="10" style="display: flex; align-items: center;">
                                {{itemL.optionBody}}
                              </v-col>
                              <v-card class="w-100" v-if="((itemL.checked)&&(itemL.freeAnswer))">
                                <v-card-text >
                                  <v-col cols="12">
                                    <v-textarea
                                        label="Ваш вариант ответа*"
                                        v-model="itemL.feeOptionBody"
                                        :rules="optionBodyRules"
                                        variant="outlined"
                                        :counter="1000"
                                        hint="Введите Ваш вариант ответа и нажмите на кнопку сохранить"
                                        required
                                    ></v-textarea>
                                  </v-col>
                                </v-card-text>
                                <v-card-actions class="card_ligth_actions" >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      rounded
                                      variant="outlined"
                                      @click="saveFreeAnswer(itemL.id, itemL.feeOptionBody,  this.uuid)"
                                  >
                                    Сохранить
                                  </v-btn>
                                </v-card-actions>

                              </v-card>


                            </v-row>

                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-responsive>
                <div></div>
                <v-responsive
                    class="mx-auto mb-8"
                    width="56"
                >
                  <v-divider class="mb-1"></v-divider>
                  <v-divider></v-divider>

                </v-responsive>
                <v-responsive class="mx-auto mb-8" v-if="show===true">
                  <v-btn
                      rounded="pill"
                      size="x-large"
                      variant="outlined"
                      class="ma-8"
                      @click="endQuestionnaire"
                  >
                    завершить анкету
                  </v-btn>
                </v-responsive>
                <v-responsive class="mx-auto mb-8" v-if="showAlert===true">

                  <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
                    Благодарим Вас за участие в анкетировании! Вы можете повторить попытку, нажав на кнопку  "Заполнить анкету"
                  </v-responsive>
                </v-responsive>
              </div>
            </v-container>
            <div class="py-12"></div>
          </div>
        </section>

      </div>

      <div style="display: flex;
      flex-direction: column;
      " class="align-self-start">
        <site-footer ></site-footer>
      </div>
    </v-main>
  </v-layout>
</template>

<script>
import {router} from "@/service/router"
import SiteFooter from "@/components/sections/SiteFooter"
import { useTheme } from 'vuetify'
import {apiUrl} from "@/service/user.service";

export default {
  name: 'QuestionnaireSurvey',
  setup () {
    const theme = useTheme()

    if (localStorage.colorTheme) {
      console.log("theme exist :" + localStorage.colorTheme)
      theme.global.name.value = localStorage.colorTheme
    } else {
      theme.global.name.value = 'myCustomLightTheme'
    }

    return {
      theme,
      toggleTheme: () => {

        if(theme.global.current.value.dark) {
          localStorage.colorTheme = 'myCustomLightTheme'
          theme.global.name.value = 'myCustomLightTheme'
          console.log("set ligth")
        } else {
          localStorage.colorTheme = 'dark'
          theme.global.name.value = 'dark'
          console.log("set dark")
        }
      }
    }
  },
  components: {
    SiteFooter,
  },

  data () {
    return {
      freeAnswerBody:'',
      showAlert:false,
      drawer: false,
      uuid: null,
      checklistId:1,
      attempt:null,
      show:false,
      cretarias: [],
      showLoader:false,
      optionBodyRules:
          [
            v => !!v || 'Поле обязательно для заполнения!',
            v => (v && v.length <= 1000) || 'Значение до должно превышать 1000 символов!',
          ],
    }
  },
  watch: {
    uuid(newUuid) {
      localStorage.conference_kras_uuid = newUuid;
    }
  },
  mounted() {
    if (localStorage.conference_kras_uuid) {
      this.uuid = localStorage.conference_kras_uuid;
    }
  },
  computed: {
    sortedCretarias() {
      return _.orderBy(this.cretarias, 'id', 'asc');
    },


  },
  methods: {
    goToAnket(){
      router.push('/questionnaire_survey').then(r => {console.log("error: " + r)})

    },

    sortedOptions(options) {
      return _.orderBy(options.optionList, 'id', 'asc');
    },
    endQuestionnaire(){
      localStorage.clear()
      this.uuid=undefined
      this.show=false
      this.showAlert = true;

    },
    saveFreeAnswer(id, freeAnswerBody,  uuid){
      let freeAnswer = {
        id: id,
        freeAnswerBody: freeAnswerBody,
        uuid: uuid
      }

      this.axios.post(apiUrl +'/internal/checklist/attempt/criteria/freeanswer', freeAnswer)
          .then(response => {
            if (response.status === 202) {
              //this.cretarias
              let index = getIndex(this.cretarias, response.data.id)
              this.cretarias.splice(index, 1, response.data)

              console.log("save")

            }  else {
              console.log("fuckup")
            }
            console.log(response)

          }).then( this.show = true)
          .catch(function (error) {
            console.log(error)
            if(error.response.status===401){
              router.push('/login').then(r => {console.log("error: " + r)})
            }
          })


    },

    selectItem(state, qId, aId){

      let optionEvent = {
        uuid: this.uuid,
        state: state,
        criteriId: qId,
        optionId: aId,
      }

      this.axios.post(apiUrl +'/internal/checklist/attempt/criteria', optionEvent)
          .then(response => {
            if (response.status === 202) {
              let index = getIndex(this.cretarias, response.data.id)
              this.cretarias.splice(index, 1, response.data)
              console.log("save")

            }  else {
              console.log("fuckup")
            }
            console.log(response)

          }).then( this.show = true)
          .catch(function (error) {
            console.log(error)
            if(error.response.status===401){
              router.push('/login').then(r => {console.log("error: " + r)})
            }
          })
    },
    goToHome () {
      router.push('/').then(r => {console.log("error: " + r)})
    },
    beginQuestionnaire(){
      this.showAlert=false
      this.showLoader=true
      let questReq = {
        uuid: this.uuid,
        checklistId: this.checklistId,
      }
      this.axios.post(apiUrl +'/internal/checklist/attempt', questReq)
          .then(response => {
            if (response.status === 201) {
              this.attempt = response.data
              this.uuid = response.data.uuid
              this.cretarias = response.data.cretarias
              this.show = true
              this.showLoader=false
              console.log(this.attempt)

            } else if (response.status === 200) {
              this.attempt = response.data
              this.uuid = response.data.uuid
              this.cretarias = response.data.cretarias
              this.show = true
              this.showLoader=false
              console.log(this.attempt)

              } else {
              alert("что то пошло не так")
              console.log(response)
            }
          }).then()
          .catch(function (error) {
            console.log(error)
            localStorage.clear()
           // location.reload()
          })
    },


  },
  created: function () {

  }
}
import _ from 'lodash';

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

</script>
<style>
#app {
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
body {
  font-family: "Montserrat";
}


</style>