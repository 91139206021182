<template>
  <v-layout >
          <v-app-bar
              prominent
              :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'"
          >

            <v-app-bar-nav-icon v-if="loggedIn"  variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>Эврика</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="makeLogout" v-if="loggedIn" variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket"/></v-btn>
            <v-btn @click="makeLogin" v-else variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket"/></v-btn>
            <v-btn icon @click="toggleTheme">
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-btn>
            <v-btn icon @click="goToAnket">
              <v-icon>mdi-clipboard-list</v-icon>
            </v-btn>

          </v-app-bar>
          <v-navigation-drawer
              class="v-navigation-drawer"
              :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'"
              v-model="drawer"
              expand-on-hover
              rail
          >
            <v-list
                :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'"
            >
              <v-list-item v-if="((myProfile!==null)&(myProfile.personAvatar!==null))"
                           :prepend-avatar="'/avatar/' + myProfile.personAvatar"
                           :title="myProfile.firstname + ' ' + myProfile.lastname"
                           :subtitle=myProfile.userEmail
              ></v-list-item>
              <v-list-item v-else
                           :title="myProfile.firstname + ' ' + myProfile.lastname"
                           :subtitle=myProfile.userEmail
              ></v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list
                density="compact"
                nav
                :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'"
            >
              <v-list-item prepend-icon="mdi-keyboard-backspace" title="Назад"  @click="goHome"></v-list-item>
              <v-list-item prepend-icon="mdi-account" title="Пользователи"  @click="sidebarItem=0" v-if="isAdmin"></v-list-item>
              <v-list-item prepend-icon="mdi-clipboard-list" title="Опросы"  @click="sidebarItem=1"></v-list-item>
            </v-list>
          </v-navigation-drawer>
          <v-main
              class="bg-success align-self-start"
              :class="$vuetify.display.smAndUp ? 'big_footer_radius_main' : 'small_footer_radius_main'">
                <div
                    grid-list-md
                    text-xs-center
                    style="display: flex;
                    min-height: 92vh;
                    flex-direction: column;
                    justify-content: space-between;">
                  <v-layout row wrap >
                    <v-container class="align-self-start my-10">
                      <admin-user-page v-if="sidebarItem===0 & isAdmin"></admin-user-page>
                      <AdminChecklist v-if="sidebarItem===1"></AdminChecklist>

                    </v-container>
                  </v-layout>
                </div>
            <div style="display: flex;
      flex-direction: column;
      " class="align-self-start">
              <site-footer ></site-footer>
            </div>
          </v-main>
  </v-layout>
</template>

<script>


import AdminUserPage from "@/components/Admin/AdminUsersPage"
import {router} from "@/service/router";
import { useTheme } from 'vuetify'
import {apiUrl} from "@/service/user.service";
import SiteFooter from "@/components/sections/SiteFooter";
import AdminChecklist from "@/components/Admin/AdminChecklist";



export default {
  name: 'AdminPage',
  setup () {
    const theme = useTheme()

    if (localStorage.colorTheme) {
      console.log("theme exist :" + localStorage.colorTheme)
      theme.global.name.value = localStorage.colorTheme
    } else {
      theme.global.name.value = 'myCustomLightTheme'
    }

    return {
      theme,
      toggleTheme: () => {
        if(theme.global.current.value.dark) {
          localStorage.colorTheme = 'myCustomLightTheme'
          theme.global.name.value = 'myCustomLightTheme'
          console.log("set ligth")
        } else {
          localStorage.colorTheme = 'dark'
          theme.global.name.value = 'dark'
          console.log("set dark")
        }
      }

       // theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'}
    }
  },
  components: {
    SiteFooter,
    AdminUserPage,
    AdminChecklist,

  },
  props: {
    source: String,
  },
  data() {
    return {
      drawer: true,
      sidebarItem: '',
      userInfo:'',
      myProfile: ''
    }
  },
  watch: {
    sidebarItem(newSidebarItem) {
      console.log("newSidebarItem: " + newSidebarItem)
      if(localStorage.AdminSidebarItem !== newSidebarItem){
        localStorage.AdminSidebarItem = newSidebarItem;
      }
    },

  },

  mounted() {
    if (localStorage.AdminSidebarItem) {
      this.sidebarItem = JSON.parse(localStorage.AdminSidebarItem)
    } else {
      this.sidebarItem=0


    }
  },
  computed: {
    isParent() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_PARENT')
      } else return false

    },
    isChild() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_CHILD')
      } else return false

    },
    isTeacher() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TEACHER')
      } else return false

    },
    isTutor() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TUTOR')
      } else return false
    },
    isAdmin() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_ADMIN')
      } else return false
    },
    isMaster() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_MASTER')
      } else return false
    },

    loggedIn () {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    alert() {
      console.log('this.$store.state.alert' + this.$store.state.alert)
      return this.$store.state.alert
    }
  },
  methods: {
    goToAnket(){
      router.push('/questionnaire_survey').then(r => {console.log("error: " + r)})

    },

    makeLogin() {
      router.push('/login').then(r => {console.log("error: " + r)})
    },
    makeLogout() {
    this.$store.dispatch('authentication/logout')
    this.$store.dispatch('alert/clear')
    this.$router.push("/").then(r => {console.log("error: " + r)})
  },
    goHome(){
      router.push('/').then(r => {console.log("error: " + r)})
    }
},
  created() {

    if(this.user){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.axios
          .get(apiUrl +'/user/i', jwtTokenConfig)
          .then(res => {
            this.myProfile = res.data
            console.log('myProfile: ', res.data)

          }).catch(error => {
        console.log(error)
        if(error.response.status===401){
          router.push('/').then(r => {console.log("error: " + r)})
        }

      })
    } else {
      router.push('/').then(r => {console.log("error: " + r)})
    }

  }

};
</script>
<style>
.big_footer_radius {
  border-bottom-left-radius:20px;
  border-bottom-right-radius: 20px;
  opacity: .85;
  background-color: #ffffff!important;
  color: #1E88E5!important;
}
.big_footer_radius_main {
  margin-top: -65px;
}

.small_footer_radius {
  border-bottom-left-radius:5px;
  border-bottom-right-radius: 5px;
  opacity: .9;
  background-color: #ffffff!important;
  color: #1E88E5!important;
}
.small_footer_radius_main {
  margin-top: -5px;

}

</style>
