<template>
  <v-layout style=" box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);">
    <v-app-bar
        prominent
        :class="$vuetify.display.smAndDown? 'small_footer_radius' : 'big_footer_radius'"
    >
      <v-app-bar-nav-icon v-if="loggedIn"  variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>

      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn @click="makeLogout" v-if="loggedIn" variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket"/></v-btn>
      <v-btn @click="loginDialog=true" v-else variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket"/></v-btn>
      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
      <v-btn icon @click="goToAnket">
        <v-icon>mdi-clipboard-list</v-icon>
      </v-btn>

    </v-app-bar>

    <v-navigation-drawer v-if="user"
        class="v-navigation-drawer"
        :class="$vuetify.display.smAndDown ? 'big_footer_radius' : 'small_footer_radius'"
        v-model="drawer"
        expand-on-hover
        rail

    >
      <v-list  :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'">
        <v-list-item v-if="((myProfile!==null)&(myProfile.personAvatar!==null))"
                     :prepend-avatar="'/avatar/' + myProfile.personAvatar"
                     :title="myProfile.firstname + ' ' + myProfile.lastname"
                     :subtitle=myProfile.userEmail
        ></v-list-item>
        <v-list-item v-else
                     :title="myProfile.firstname + ' ' + myProfile.lastname"
                     :subtitle=myProfile.userEmail
        ></v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav   :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'">
        <v-list-item prepend-icon="mdi-home" title="Домой"  @click="sidebarItem=0" ></v-list-item>
        <v-list-item prepend-icon="mdi-account-settings" title="Профиль"  @click="sidebarItem=1" v-if="loggedIn===true"></v-list-item>
        <v-list-item prepend-icon="mdi-comment" title="Сообщения" @click="sidebarItem=2" v-if="loggedIn===true"></v-list-item>
        <v-list-item prepend-icon="mdi-folder" title="Файлы" @click="sidebarItem=3" v-if="loggedIn===true"></v-list-item>
        <v-list-item prepend-icon="mdi-screwdriver" title="Администрирование" @click="goToAdminArea" v-if="isTeacher || isTutor || isMaster || isAdmin"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main
        class="align-self-start"
        :class="($vuetify.display.smAndDown)&&(sidebarItem===0) ? 'small_footer_radius_main' : 'big_footer_radius_main'"
    >
      <div
          v-if="Number(sidebarItem)!==0"
          grid-list-md
          text-xs-center
          style="display: flex;
          min-height: 92vh;
          flex-direction: column;
          justify-content: space-between;">
        <v-layout row wrap>
          <v-container class="align-self-start my-10" >
            <ProfileCard v-if="Number(sidebarItem)===1" :user="user" :myProfile="myProfile"></ProfileCard>
            <profile-chat v-if="Number(sidebarItem)===2"></profile-chat>
            <FileCard v-if="Number(sidebarItem)===3"></FileCard>
          </v-container>
        </v-layout>
      </div>
      <div v-else>
        <v-parallax style="background: linear-gradient(135deg, rgb(159, 46, 244), rgb(249, 125, 132), rgb(255, 205, 66));"
        :with="$vuetify.display.smAndDown ? 690 : 1900"
        :height="$vuetify.display.smAndDown ? 1080 : 1200"


        >
          <div class="d-flex flex-column fill-height justify-center align-center">

            <h1 class="subheading text-lg-h2 text-xs-h7 text-sm-h4 text-md-h3  font-weight-black ma-6 " style="color: #ffffff" align="center">
              «Исследования об изменении трудовой функции учителя в современных условиях»
            </h1>

            <v-btn
                rounded="pill"
                size="x-large"
                variant="outlined"
                class="ma-8"
                color="#ffffff"
                v-scroll-to="'#action_place'"
            >
              <v-icon color="#ffffff">mdi-chevron-double-down</v-icon>
            </v-btn>
          </div>

        </v-parallax>


        <v-dialog
            v-model="loginDialog"
            persistent
        >
          <v-card >
            <v-card-title class="card_ligth_header">
              <span class="text-h5">Вход в систему</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="loginForm" lazy-validation>
                <v-text-field
                    name="username"
                    label="Имя пользователя"
                    :rules="usernameRules"
                    v-model="username"
                    hide-details="auto"
                    outlined
                    aria-required="true"
                    class="ma-2"
                    style="min-width: 350px; max-width: 350px;"
                >
                </v-text-field>
                <v-text-field
                    id="password"
                    name="password"
                    label="Пароль"
                    type="password"
                    :rules="passwordRules"
                    v-model="password"
                    outlined
                    aria-required="true"
                    class="ma-2"
                    style="min-width: 350px; max-width: 350px;"
                ></v-text-field>
                <v-alert
                    density="compact"
                    style="min-width: 350px; max-width: 350px;"
                    :type="alert.type"
                    v-if="alert.type!=null"
                    class="ma-2"
                >
                  {{ alert.message }}
                </v-alert>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_actions">
              <v-spacer></v-spacer>
              <v-btn
                  v-if="alert.type!=null"
                  variant="outlined"
                  rounded
                  text
                  @click="showReqDialog"
              >
                Сброс пароля
              </v-btn>
              <v-btn
                  variant="outlined"
                  rounded
                  text
                  @click="closeLoginDialog"
              >
                Отмена
              </v-btn>
              <v-btn
                  variant="outlined"
                  rounded
                  :disabled="loggingIn"
                  @click="makeLogin"
              >
                Войти
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="passwordReqDialog"
            persistent
        >
          <v-card >
            <v-card-title class="card_ligth_header">
              <span class="text-h5">Сброс пароля</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="resetForm" lazy-validation>
                <v-text-field
                    name="username"
                    v-show="reqSuccess===false"
                    label="Ваш email"
                    :rules="emailRules"
                    v-model="passwordReqEmail"
                    hide-details="auto"
                    outlined
                    aria-required="true"
                    class="ma-2"
                    style="min-width: 350px; max-width: 350px;"
                >
                </v-text-field>
                <v-col
                    cols="12"
                    v-show="reqSuccess===false"
                >
                  <v-switch
                      v-model="passwordReqConfirm"
                      variant="outlined"
                      requred

                      :rules="passwordReqConfirmRules"
                      label="Подтверждаю запрос на сброс пароля"

                  ></v-switch>
                </v-col>

                <v-alert
                    density="compact"
                    style="min-width: 350px; max-width: 350px;"
                    v-if="confirmStatus"
                    class="ma-2"
                >
                  {{ confirmBody }}
                </v-alert>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_actions">
              <v-spacer></v-spacer>

              <v-btn
                  variant="outlined"
                  rounded
                  text
                  @click="closeResetDialog"
              >
                Закрыть
              </v-btn>
              <v-btn
                  v-if="!reqSuccess"
                  variant="outlined"
                  rounded
                  @click="makeReset"
              >
                Отправить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
<div style="display: flex;
      flex-direction: column;
      " class="align-self-start">
  <site-footer ></site-footer>
</div>


    </v-main>


  </v-layout>
</template>

<script>
import {router} from "@/service/router"
import ProfileCard from '@/components/Profile/ProfileCard.vue'
import FileCard from '@/components/Profile/FileCard'
import ProfileChat from "@/components/Profile/ProfileChat"
import SiteFooter from "@/components/sections/SiteFooter";


import { useTheme } from 'vuetify'
import {apiUrl} from "@/service/user.service";




export default {
  name: 'HomePage',
  setup () {
    const theme = useTheme()

    if (localStorage.colorTheme) {
      console.log("theme exist :" + localStorage.colorTheme)
      theme.global.name.value = localStorage.colorTheme
    } else {
      theme.global.name.value = 'myCustomLightTheme'
    }

    return {
      theme,
      toggleTheme: () => {

        if(theme.global.current.value.dark) {
          localStorage.colorTheme = 'myCustomLightTheme'
          theme.global.name.value = 'myCustomLightTheme'
          console.log("set ligth")
        } else {
          localStorage.colorTheme = 'dark'
          theme.global.name.value = 'dark'
          console.log("set dark")
        }
      }
    }
  },
  components: {
    ProfileCard,
    FileCard,
    SiteFooter,
    ProfileChat,
  },
  watch: {
    sidebarItem(newSidebarItem) {
      console.log("newSidebarItem: " + newSidebarItem)
      if(localStorage.ProfileSidebarItem !== newSidebarItem){
        localStorage.ProfileSidebarItem = newSidebarItem;
      }
    },

    loggedIn(newLoggedIn){
      if(newLoggedIn){
        this.loginDialog=false
        console.log("User successful login")
        location.reload()
      } else {
        console.log("User not login")
      }
    },

  },
  mounted() {
    if (localStorage.ProfileSidebarItem) {
      this.sidebarItem = JSON.parse(localStorage.ProfileSidebarItem)
    } else {
      this.sidebarItem=0
    }
  },
  data () {
    return {
      inHall:false,
      inOnline:false,
      passwordReqDialog:false,
      passwordReqConfirm: false,
      passwordReqEmail: '',
      confirmBody: '',
      confirmStatus:false,
      personEmail:'',
      firstname:'',
      secname:'',
      lastname:'',
      personOrganization:'',
      personOrganizationPostAddress:'',
      personSpeciality:'',
      personMobilePhone:'',
      passwordConfirm:'',


      drawer: false,
      sidebarItem: 0,
      pdAgree:false,
      uAgree:false,
      loginDialog:false,
      reqSuccess:false,
      usernameRules: [
        v => !!v || 'Укажите имя пользователя!',
      ],
      lastnameRules: [
        v => !!v || 'Укажите фамилию!',
        v => (v && v.length < 50) || 'не более 50 символов',
      ],
      firstnameRules: [
        v => !!v || 'Укажите имя!',
        value => (value && value.length < 50) || 'не более 50 символов',
      ],
      secnameRules: [
        v => !!v || 'Укажите отчество!',
        value => (value && value.length < 50) || 'не более 50 символов',
      ],
      personOrganizationRules: [
        v => !!v || 'Укажите организацию!',
        value => (value && value.length < 250) || 'не более 250 символов',
      ],
      personSpecialityRules: [
        v => !!v || 'Укажите специальность!',
        value => (value && value.length < 250) || 'не более 250 символов',
      ],
      emailRules: [
        v => !!v || 'Укажите адрес электронной почты!!',
        value => (value && value.length < 50) || 'не более 50 символов',
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail не валидный'

      ],
      personMobilePhoneRules: [
        v => !!v || 'Укажите контактный телефон!!',
        value => (value && value.length < 50) || 'не более 50 символов',
      ],
      personOrganizationPostAddressRules: [
        v => !!v || 'Укажите контактный телефон!!',
        value => (value && value.length < 250) || 'не более 250 символов',
      ],
      passwordRules: [
        v => !!v || 'Укажите пароль!',
        value => (value && value.length >= 10) || 'Не менее 10 символов!',
      ],
      pdAgreeRules: [
        v => !!v  || 'Необходимо дать согласие на обработку персональных данных!',

      ],
      regionRules: [
        v => !!v  || 'Необходимо указать регион!',

      ],
      uAgreeRules: [
        v => !!v || 'Необходимо принять пользовательское соглашение!',

      ],
      passwordReqConfirmRules: [
        v => !!v  || 'Необходимо дать согласие на сброс пароля!',

      ],
      username: '',
      password: '',
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 10) || 'Min 10 characters',
      ],
      myProfile: {},

      menu: [
        { text: 'Главная', link: '/' },
        { text: 'Пользовательское соглашение', link: '/user_agreement' },
        { text: 'Политика обработки ПД', link: '/personal_data_politic' },
      ],
      passwordMismatch:false,
      userRegion:null,
      regions: [
        { regionName: 'Республика Адыгея (Адыгея)' },
        { regionName: 'Республика Башкортостан' },
        { regionName: 'Республика Бурятия' },
        { regionName: 'Республика Алтай' },
        { regionName: 'Республика Дагестан' },
        { regionName: 'Республика Ингушетия' },
        { regionName: 'Кабардино-Балкарская Республика' },
        { regionName: 'Республика Калмыкия' },
        { regionName: 'Карачаево-Черкесская Республика' },
        { regionName: 'Республика Коми' },
        { regionName: 'Республика Марий Эл' },
        { regionName: 'Республика Мордовия' },
        { regionName: 'Республика Саха (Якутия)' },
        { regionName: 'Республика Северная Осетия - Алания' },
        { regionName: 'Республика Татарстан (Татарстан)' },
        { regionName: 'Республика Тыва' },
        { regionName: 'Удмуртская Республика' },
        { regionName: 'Республика Хакасия' },
        { regionName: 'Чеченская Республика' },
        { regionName: 'Чувашская Республика - Чувашия' },
        { regionName: 'Алтайский край' },
        { regionName: 'Краснодарский край' },
        { regionName: 'Красноярский край' },
        { regionName: 'Приморский край' },
        { regionName: 'Ставропольский край' },
        { regionName: 'Хабаровский край' },
        { regionName: 'Амурская область' },
        { regionName: 'Архангельская область' },
        { regionName: 'Астраханская область' },
        { regionName: 'Белгородская область' },
        { regionName: 'Брянская область' },
        { regionName: 'Владимирская область' },
        { regionName: 'Волгоградская область' },
        { regionName: 'Вологодская область' },
        { regionName: 'Воронежская область' },
        { regionName: 'Ивановская область' },
        { regionName: 'Иркутская область' },
        { regionName: 'Калининградская область' },
        { regionName: 'Калужская область' },
        { regionName: 'Камчатский край' },
        { regionName: 'Кемеровская область' },
        { regionName: 'Кировская область' },
        { regionName: 'Костромская область' },
        { regionName: 'Курганская область' },
        { regionName: 'Курская область' },
        { regionName: 'Ленинградская область' },
        { regionName: 'Липецкая область' },
        { regionName: 'Магаданская область' },
        { regionName: 'Московская область' },
        { regionName: 'Мурманская область' },
        { regionName: 'Нижегородская область' },
        { regionName: 'Новгородская область' },
        { regionName: 'Новосибирская область' },
        { regionName: 'Омская область' },
        { regionName: 'Оренбургская область' },
        { regionName: 'Орловская область' },
        { regionName: 'Пензенская область' },
        { regionName: 'Пермский край' },
        { regionName: 'Псковская область' },
        { regionName: 'Ростовская область' },
        { regionName: 'Рязанская область' },
        { regionName: 'Самарская область' },
        { regionName: 'Саратовская область' },
        { regionName: 'Сахалинская область' },
        { regionName: 'Свердловская область' },
        { regionName: 'Смоленская область' },
        { regionName: 'Тамбовская область' },
        { regionName: 'Тверская область' },
        { regionName: 'Томская область' },
        { regionName: 'Тульская область' },
        { regionName: 'Тюменская область' },
        { regionName: 'Ульяновская область' },
        { regionName: 'Челябинская область' },
        { regionName: 'Забайкальский край' },
        { regionName: 'Ярославская область' },
        { regionName: 'Г. Москва' },
        { regionName: 'Санкт-Петербург' },
        { regionName: 'Еврейская автономная область' },
        { regionName: 'Ненецкий автономный округ' },
        { regionName: 'Ханты-Мансийский автономный округ - Югра' },
        { regionName: 'Чукотский автономный округ' },
        { regionName: 'Ямало-Ненецкий автономный округ' },
        { regionName: 'Иные территории, включая город и космодром Байконур' },
        { regionName: 'Севастополь' },
        { regionName: 'Херсонская область' },
        { regionName: 'Запорожская область' },
        { regionName: 'Луганская Народная Республика' },
        { regionName: 'Республика Крым' },
        { regionName: 'Донецкая Народная Республика' }
      ],
    }
  },
  computed: {

    loggingIn () {
      return this.$store.state.authentication.status.loggingIn
    },
    isParent() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_PARENT')
      } else return false

    },
    isChild() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_CHILD')
      } else return false

    },
    isTeacher() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TEACHER')
      } else return false

    },
    isTutor() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TUTOR')
      } else return false
    },
    isAdmin() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_ADMIN')
      } else return false
    },
    isMaster() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_MASTER')
      } else return false
    },
    loggedIn () {

      return this.$store.state.authentication.status.loggedIn
    },
    user () {
      return this.$store.state.authentication.user
    },
    authentication () {
      return this.$store.state.authentication
    },
    alert () {
      console.log('this.$store.state.alert' + this.$store.state.alert)
      return this.$store.state.alert

    }

  },

  methods: {
    onChangeInOnline(){
      if(this.inOnline===true){
        this.inHall = false
      }
    },
    onChangeInHall(){
      if(this.inHall===true){
        this.inOnline = false
      }
    },
    showReqDialog(){
      this.loginDialog = false
      this.passwordReqDialog =true
    },

    makeReset(){
      this.$refs.resetForm.validate().then(responce=>{
            if(responce.valid){
             let passwordReq = {
               userEmailForRequest: this.passwordReqEmail

              }
              this.axios.post(apiUrl +'/auth/password_req', passwordReq)
                  .then(response => {
                    if (response.status === 202) {

                      this.$refs.regForm.reset()
                      this.passwordReqEmail = ''
                      this.passwordReqConfirm = false
                      this.confirmStatus = true
                      this.reqSuccess = true
                      this.confirmBody = 'Ссылка для сброса пароля отправленна на Ваш  Email'

                    } else {
                      this.passwordReqEmail = ''
                      this.passwordReqConfirm = false
                      this.confirmStatus = true
                      this.reqSuccess = false
                      this.confirmBody = 'Не корректный Email'

                    }
                    console.log(response)
                  })
                  .catch(function (error) {
                    console.log(error)
                    if (error.code==="ERR_NETWORK") {
                      alert("Сервер не доступен! Проверьте свое подключение и попробуйте нажать кнопку еще раз!");
                    } else {
                      if(error.response.status===401){
                        router.push('/login').then(r => {console.log("error: " + r)})
                      }
                      if(error.response.status===400){
                        alert("Email уже зарегистрирован в системе!")
                      }
                    }
                  })

            }})

    },
    closeResetDialog(){
      this.passwordReqEmail = ''
      this.passwordReqConfirm = false
      this.confirmStatus = false
      this.confirmBody = ''
      this.reqSuccess = false
      this.passwordReqDialog =false
      this.loginDialog = false
    },
    goToAnket(){
      router.push('/questionnaire_survey').then(r => {console.log("error: " + r)})

    },
    singUp(){
      this.$refs.regForm.validate().then(responce=>{
        if(responce.valid){
          if(this.password===this.passwordConfirm){
            this.passwordMismatch=false
            if( !!this.inOnline || !!this.inHall){
              let signUpData ={
                firstname: capitalizeFirstLetter(this.firstname),
                secname: capitalizeFirstLetter(this.secname),
                lastname: capitalizeFirstLetter(this.lastname),
                personEmail: this.personEmail,
                personOrganization: capitalizeFirstLetter(this.personOrganization),
                personSpeciality: capitalizeFirstLetter(this.personSpeciality),
                personMobilePhone: this.personMobilePhone,
                passwordConfirm: this.passwordConfirm,
                password: this.password,
                inHall: this.inHall,
                inOnline: this.inOnline,
                userRegion: this.userRegion

              }
              this.axios.post(apiUrl +'/auth/signup', signUpData)
                  .then(response => {
                    if (response.status === 201) {
                      alert("Вы успешно зарегистрированны!")

                      this.pdAgree = false
                      this.uAgree = false
                      this.inHall = false
                      this.inOnline =false
                      this.userRegion = null
                      this.$refs.regForm.reset()
                    } else {
                      alert("что то пошло не так")
                    }
                    console.log(response)
                  })
                  .catch(function (error) {
                    console.log(error)
                    if (error.code==="ERR_NETWORK") {
                      alert("Сервер не доступен! Проверьте свое подключение и попробуйте нажать кнопку еще раз!");
                    } else {
                      if(error.response.status===401){
                        router.push('/login').then(r => {console.log("error: " + r)})
                      }
                      if(error.response.status===400){
                        alert("Email уже зарегистрирован в системе!")
                      }
                    }
                  })
            } else {
              alert("Необходимо выбрать форму участия(онлайн или очно)!")
            }


          } else {
            alert("Не совпадает пароль и его подтверждение! Исправьте ошибку и нажмите кнопку еще раз!")
            this.passwordMismatch=true
          }


        } else {
          alert("Проверьте корректность заполнения полей формы регистрации! После исправления ошибок и заполнения всех полей нажмите на кнопку еще раз.")
        }
      })
    },
    goToInternalUse(){
      router.push('/internal_use').then(r => {console.log("error: " + r)})
    },

    goToProfile () {
      router.push('/pk').then(r => {console.log("error: " + r)})
    },
    closeLoginDialog(){
      this.$store.dispatch('alert/clear')
      this.password=''
      this.username=''
      this.loginDialog=false
    },
    goToWebinarAdminArea () {
      router.push('/webinar_admin_area').then(r => {console.log("error: " + r)})
    },

    makeLogin () {
        this.$refs.loginForm.validate().then(responce => {
          if (responce.valid){
            const { username, password } = this
            const { dispatch } = this.$store
            dispatch('authentication/login', { username, password })
            console.log("this.authentication.status: " + this.authentication.status)

          }
        }).then(result => {
          console.log("result: " + result)
          if(this.authentication.status.loggedIn===true){
            this.loginDialog= false
          }
        } )


    },
      makeLogout () {
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        localStorage.clear()
        this.sidebarItem=0

      },

    goToAdminArea() {
      router.push('/admin').then(r => {console.log("error: " + r)})

    }
  },
  created: function () {

    if(this.user!==null){
      let jwtTokenConfig = {
        headers: {
        Authorization: "Bearer " + this.$store.state.authentication.user.token
      }
    }
    this.axios
      .get(apiUrl +'/user/i', jwtTokenConfig)
      .then(res => {
        this.myProfile = res.data
        console.log('myProfile: ', res.data)

      }).catch(error => {
    console.log(error)
    if(error.response.status===401){
      this.$store.dispatch('authentication/logout')
      this.$store.dispatch('alert/clear')
      localStorage.clear()
      this.sidebarItem=0
    }

  })
} else {
      this.sidebarItem=0
    }
  }
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


</script>
<style>
#app {
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
body {
  font-family: "Montserrat";
}
.title {
  font-size: 1.25rem!important;
  font-weight: 500;
  letter-spacing: .0125em!important;
}
.big_footer_radius {
  border-bottom-left-radius:20px;
  border-bottom-right-radius: 20px;
  opacity: .85;
  background-color: #ffffff!important;
  color: #1E88E5 !important;
}
.big_footer_radius_main {
  margin-top: -65px;
}

.small_footer_radius {
  border-bottom-left-radius:5px;
  border-bottom-right-radius: 5px;
  opacity: .9;
  background-color: #ffffff!important;
  color: #1E88E5 !important;
}
.small_footer_radius_main {
  margin-top: -5px;

}
.card_ligth_header {

  background-color: #ffffff!important;
  color: #1E88E5 !important;

}
.card_ligth_actions {

  background-color: #ffffff!important;
  color: #1E88E5 !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.v-card{
  box-shadow: 0 0 2px 2px rgba(221, 221, 221, 1);
  opacity: .95;

}

</style>