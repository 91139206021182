import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/HomePage'
import ProfilePage from '../components/Profile/ProfilePage'
import AdminPage from "@/components/Admin/AdminPage";
import QuestionnaireSurvey from "@/components/sections/QuestionnaireSurvey";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: QuestionnaireSurvey },
    { path: '/main', component: HomePage },
    { path: '/pk', component: ProfilePage },
    { path: '/admin', component: AdminPage },
    { path: '/questionnaire_survey', name:'questionnaire_survey', component: QuestionnaireSurvey },

  ]
})
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  //const publicPages = [ '/' ]
  const publicPages = [ '/','/questionnaire_survey', '/main' ]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    return next('/')
  }
  next()
})
