
import { authHeader } from '../util/auth-headers'
//export const apiUrl = 'https://znanie-backend.geekbase.ru/api'
//export const apiUrl = 'http://localhost:8181/api'
export const apiUrl = 'https://questionnaire-backend.eurekacenter.ru/api'
export const userService = {
  getAll,
  login
}

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  console.log('auth headers: ' + requestOptions.headers)
  return fetch(`${apiUrl}/user`, requestOptions).then(handleResponse)
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
       // this.$store.dispatch('authentication/logout')
        //location.reload(true)
      }
      const error = (data && data.message) || response.statusText
      console.log("login error: "+  error)
      return Promise.reject(error)
    } else {
      return data
    }

  })
}

function login(username, password) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  return fetch(`${apiUrl}/auth/signin`, requestOptions)

      .then(handleResponse)
      .then(user => {
       if (user.token) {
          localStorage.setItem('user', JSON.stringify(user));
        }
       return user;
      });
}
